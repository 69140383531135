import React from "react"
import { Link } from "gatsby"

import Navbar from '../components/Nav'
import Footer from '../components/Footer'

import { StaticImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby';
import { convertToBgImage } from "gbimage-bridge"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import BackgroundImage from 'gatsby-background-image'

import { useMediaQuery } from 'react-responsive';

export default function Home({data}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isDesktop = useMediaQuery({query : '(max-width: 1024px)'})

  const {content} = data.file;
  const heroImage = getImage(data.hero.childImageSharp.gatsbyImageData);
  const bgImage = convertToBgImage(heroImage)
  const sierraVista = convertToBgImage(getImage(data.sierra_vista.childImageSharp.gatsbyImageData));
  const delaField = convertToBgImage(getImage(data.dela_field.childImageSharp.gatsbyImageData));
  const reeseVillas = convertToBgImage(getImage(data.reese_villas.childImageSharp.gatsbyImageData));
  const fijiVillas = convertToBgImage(getImage(data.fiji_villas.childImageSharp.gatsbyImageData));
  const harmonVillas = convertToBgImage(getImage(data.harmon_villas.childImageSharp.gatsbyImageData));
  const murdeauxVillas = convertToBgImage(getImage(data.murdeaux_villas.childImageSharp.gatsbyImageData));


  let propertiesToShow = 1;
  let centerModeStatus = false;
  if (isMobile) {
    propertiesToShow = 1;
  } else if(isDesktop) {
    centerModeStatus = true;
    propertiesToShow = 2;
  } else {
    centerModeStatus = true;
    propertiesToShow = 4;
  }



  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  };

  const featuredSettings = {
    dots: false,
    infinite: true,
    centerPadding: "60px",
    arrows: true,
    speed: 1000,
    slidesToShow: propertiesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    centerMode: centerModeStatus
  };

  return (
    <div>
      <Navbar isActive="Home"/>
      <BackgroundImage Tag="section" className="h-screen" {...bgImage} backgroundColor={"#272D2D"} preserveStackingContext>
              <div className="px-8 absolute bottom-24 md:bottom-28">
                <h1 className="py-2 text-5xl text-sphinx-white font-montserrat font-medium md:text-6xl">Sphinx <span className=" font-light">Residential</span></h1>
                <h2 className="py-2 text-xl text-sphinx-white font-montserrat font-light md:text-2xl">Because everyone deserves a place to call home</h2>
                <Link to="/about" className="text-sm text-center p-3 max-w-max text-sphinx-white font-montserrat flex items-center bg-sphinx-accent md:text-base hover:bg-sphinx-accent hover:shadow-lg">
                  About Us
                </Link>
              </div>
      </BackgroundImage>

      {/* First Section */}
      <section className="bg-sphinx-background-white">
        <div className="container mx-auto px-8 flex flex-wrap items-start md:flex-nowrap md:py-8 max-w-6xl">
          <h3 className="pt-10 text-2xl text-center text-sphinx-blue font-montserrat font-medium md:pt-0 md:text-left md:text-3xl md:pr-24">{content.firstSectionHeading}</h3>
          <div>
            <p className="py-8 text-base font-sanspro text-gray-600 tracking-wide leading-loose md:py-0 font-light">
            {content.firstSectionParagraph}
            </p>
            <div className="hidden text-center md:inline-block float-right">
              <StaticImage src="../images/Icons/stats-icon.svg" alt="stats-chart-icon" height={50}/>
            </div>
          </div>
        </div>
      
        {/* Stats Section */}
        <div className="container mx-auto px-8 flex flex-wrap content-center items-center md:flex-nowrap md:pb-8 max-w-6xl">
          <div className="text-center text-sphinx-blue w-3/6 md:py-2 md:border-t md:border-r">
            <h2 className="font-montserrat font-medium text-3xl">1650+</h2>
            <h6 className="pt-2 font-sanspro">Apartment <br/> Homes</h6>
          </div>

          <div className="text-center w-3/6 md:hidden">
            <StaticImage src="../images/Icons/stats-icon.svg" alt="stats-chart-icon" height={50}/>
          </div>

          <div className="text-center text-sphinx-blue  w-3/6 py-9 md:py-2 md:border-t md:border-r">
            <h2 className="font-montserrat font-medium text-3xl">25+</h2>
            <h6 className="pt-2 font-sanspro">Years Of <br/> Experience</h6>
          </div>

          <div className="text-center text-sphinx-blue  w-3/6 py-9 md:py-2 md:border-t">
            <h2 className="font-montserrat font-medium text-3xl">4</h2>
            <h6 className="pt-2 font-sanspro">Projects Under <br/> Development</h6>
          </div>
        </div>
      </section>

      {/* Who We Are */}
      <section className="bg-sphinx-background-white">
        <div className="relative" style={{height: 500}}>
          <div className="w-full absolute">
            <Slider {...settings}>
              <div className="h-full">
              <BackgroundImage Tag="section" className="w-full relative" style={{height: 500}} {...sierraVista} backgroundColor={"#E7EBEA"} preserveStackingContext>
                <div className="p-2 absolute bottom-0 right-0 text-lg text-sphinx-white font-montserrat font-medium italic">
                  <h1>Sierra Vista Senior Villas</h1>
                </div>
              </BackgroundImage>
              </div>
              <div>
              <BackgroundImage Tag="section" className="w-full relative" style={{height: 500}} {...delaField} backgroundColor={"#E7EBEA"} preserveStackingContext>
              <div className="p-2 absolute bottom-0 right-0 text-lg text-sphinx-white font-montserrat font-medium italic">
                  <h1>Delafield Villas</h1>
                </div>
              </BackgroundImage>
              </div>
              <div>
              <BackgroundImage Tag="section" className="h-full w-full relative" style={{height: 500}} {...reeseVillas} backgroundColor={"#E7EBEA"} preserveStackingContext>
              <div className="p-2 absolute bottom-0 right-0 text-lg text-sphinx-white font-montserrat font-medium italic">
                  <h1>Reese Court Villas</h1>
                </div>
              </BackgroundImage>
              </div>
            </Slider>
          </div> 

        </div>
      </section>

      {/* Featured Properties */}

      <section className="bg-sphinx-background-white-2 py-10">
        <div className=" mb-10 container px-8 md:pt-6 mx-auto max-w-5xl flex flex-wrap md:flex-nowrap justify-center items-start md:space-x-8 bg-sphinx-background-white-2 space-y-8 md:space-y-0">
          <div className="w-full md:w-2/3 space-y-7">
            <h2 className="text-2xl text-sphinx-blue font-montserrat font-medium">{content.secondSectionHeading}</h2>
            <a href="/about" className="text-sm text-center p-3 max-w-max text-sphinx-white font-montserrat flex items-center bg-sphinx-accent md:text-base hover:bg-sphinx-accent hover:shadow-lg">Sphinx Development</a>
          </div>
          <div>
            <p className="font-montserrat font-medium text-xl font-sanspro text-gray-900">{content.secondSectionParagraphHeading}
            </p>
            <p className="mt-5 text-lg font-sanspro text-gray-900 tracking-wide leading-loose font-light">
            {content.secondSectionParagraph}</p>
          </div>
        </div>
      </section>

      <section className="m-10">
        
        <div className="h-auto">
        <div className="flex flex-wrap justify-center mb-10 ">
          <h3 className="text-2xl text-center text-sphinx-blue font-montserrat font-medium w-full mb-2">Featured Properties</h3>
          <Link to="/portfolio" className="text-sm text-center mt-5 p-2 max-w-max text-sphinx-accent font-montserrat border-sphinx-accent border-2 flex items-center hover:bg-sphinx-accent hover:text-random-white hover:shadow-lg"> View Properties</Link>
        </div>
          <Slider {...featuredSettings}>

              <div className="md:px-5">
                <BackgroundImage Tag="section" className="flex justify-center items-center h-64" {...delaField} backgroundColor={"#F9F9F9"} preserveStackingContext>
                  <div className="flex flex-wrap justify-center items-center w-full h-full relative">
                        <div className="flex flex-wrap justify-center">
                            <h5 className="w-full text-center text-2xl text-sphinx-white font-montserrat font-semibold">Delafield Villas</h5>
                            <h6 className="text-center text-lg text-sphinx-white font-montserrat font-medium">Multi-Family / Dallas, Texas</h6>
                        </div>
                    </div>
                </BackgroundImage>
              </div>
            
            
              <div className="md:px-5">
                <BackgroundImage Tag="section" className="flex justify-center items-center h-64" {...fijiVillas} backgroundColor={"#F9F9F9"} preserveStackingContext>
                  <div className="flex flex-wrap justify-center items-center h-full relative">
                        <div className="flex flex-wrap justify-center">
                            <h5 className="w-full text-center text-2xl text-sphinx-white font-montserrat font-semibold">Fiji Senior Villas</h5>
                            <h6 className="text-center text-lg text-sphinx-white font-montserrat font-medium">Multi-Family / Dallas, Texas</h6>
                        </div>
                    </div>
                </BackgroundImage>
              </div>
            
            
              <div className="md:px-5">
                <BackgroundImage Tag="section" className="flex justify-center items-center h-64" {...harmonVillas} backgroundColor={"#F9F9F9"} preserveStackingContext>
                  <div className="flex flex-wrap justify-center items-center h-full relative">
                        <div className="flex flex-wrap justify-center">
                            <h5 className="w-full text-center text-2xl text-sphinx-white font-montserrat font-semibold">Harmon Villas</h5>
                            <h6 className="text-center text-lg text-sphinx-white font-montserrat font-medium">Multi-Family / Fort Worth, Texas</h6>
                        </div>
                    </div>
                </BackgroundImage>
              </div>
            

              <div className="md:px-5">
                <BackgroundImage Tag="section" className="flex justify-center items-center h-64" {...reeseVillas} backgroundColor={"#F9F9F9"} preserveStackingContext>
                  <div className="flex flex-wrap justify-center items-center h-full relative">
                        <div className="flex flex-wrap justify-center">
                            <h5 className="w-full text-center text-2xl text-sphinx-white font-montserrat font-semibold">Reese Court Villas</h5>
                            <h6 className="text-center text-lg text-sphinx-white font-montserrat font-medium">Multi-Family / Dallas, Texas</h6>
                        </div>
                    </div>
                </BackgroundImage>
              </div>

              <div className="md:px-5">
                <BackgroundImage Tag="section" className="flex justify-center items-center h-64" {...murdeauxVillas} backgroundColor={"#F9F9F9"} preserveStackingContext>
                  <div className="flex flex-wrap justify-center items-center h-full relative">
                        <div className="flex flex-wrap justify-center">
                            <h5 className="w-full text-center text-2xl text-sphinx-white font-montserrat font-semibold">Murdeaux Villas</h5>
                            <h6 className="text-center text-lg text-sphinx-white font-montserrat font-medium">Multi-Family / Dallas, Texas</h6>
                        </div>
                    </div>
                </BackgroundImage>
              </div>

              <div className="md:px-5">
                <BackgroundImage Tag="section" className="flex justify-center items-center h-64" {...sierraVista} backgroundColor={"#F9F9F9"} preserveStackingContext>
                  <div className="flex flex-wrap justify-center items-center h-full relative">
                        <div className="flex flex-wrap justify-center">
                            <h5 className="w-full text-center text-2xl text-sphinx-white font-montserrat font-semibold">Sierra Vista Senior Villas</h5>
                            <h6 className="text-center text-lg text-sphinx-white font-montserrat font-medium">Multi-Family / Dallas, Texas</h6>
                        </div>
                    </div>
                </BackgroundImage>
              </div>
            </Slider>

        </div>
      </section>

      

      {/* View Properties Section */}

      <div className="py-8 flex justify-center items-center bg-sphinx-background-dark md:py-0">
        <div className="container mx-auto flex flex-wrap justify-center px-8 md:w-96">
          <h1 className="py-2 text-2xl text-center font-montserrat text-sphinx-white border-b border-sphinx-accent w-full lg:text-3xl">Broad vision.<br/>Careful thought.<br/>Hand-crafted design.</h1>
          <Link to="/construction" className="text-sm text-center mt-3 p-3 max-w-max text-sphinx-white font-montserrat flex items-center bg-sphinx-accent md:text-base hover:bg-sphinx-accent hover:shadow-lg">SDC Construction</Link>
        </div>

        <div className="hidden h-72 w-3/6 md:grid">
          <StaticImage
            style={{
              gridArea: "1/1"
            }}
            layout="fullWidth"
            aspectRatio={16 / 9}
            alt = "Image"
            src = "../images/family-construction.jpg"
            quality={100}
            formats ={["auto", "webp", "avif"]}
            ></StaticImage>
        </div>

      </div>
      
      <Footer/>
    </div>
  )
}

export const pageQuery = graphql`
  query HomePageContent {
    file(relativePath: {eq: "pages/home.yml"}) {
        content: childPagesYaml {
          heroTitle
          firstSectionParagraph
          firstSectionHeading
          secondSectionHeading
          secondSectionParagraph
          secondSectionParagraphHeading
        }
    }

    hero: file(relativePath: {eq: "Heros/dallas-skyline.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
      }
    }
    sierra_vista: file(relativePath: {eq: "Properties/Sierra Vista/sierra-vista.JPG"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
      }
    }
    dela_field: file(relativePath: {eq: "Properties/Delafield Villas/Delafield-Villas_1.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
      }
    }
    reese_villas: file(relativePath: {eq: "Properties/Reese Villas/Reese-Villas_1.JPG"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
      }
    }
    fiji_villas: file(relativePath: {eq: "Properties/Fiji/Fiji_1.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
      }
    }
    harmon_villas: file(relativePath: {eq: "Properties/Harmon Villas/Harmon-Villas_1.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
      }
    } 
    murdeaux_villas: file(relativePath: {eq: "Properties/Murdeaux Villas/murdeaux-villas.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
      }
    }

  }
`